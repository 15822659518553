import React, { FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Button } from '../../../../../components'
import * as _ from 'lodash'
import Store from './store'
import { useForm } from 'react-hook-form'
import { ConventionalOfferWageBonusDto } from '../types'
import { toast } from 'react-toastify'

interface IView {
    ConventionalOfferWageBonusStore?: Store
    data: ConventionalOfferWageBonusDto
    isEdit: boolean
    toggleModal: any
    versionId: number
}

const View: FC<IView> = inject('ConventionalOfferWageBonusStore')(
    observer((props: IView) => {
        const { ConventionalOfferWageBonusStore: store, data, isEdit, toggleModal, versionId } = props
        const { t } = useTranslation()

        const { handleSubmit } = useForm()

        const [id, setId] = useState(-1)
        const [name, setName] = useState('')
        const [isItGivenAsFixedAmount, setIsItGivenAsFixedAmount] = useState(false)
        const [baseWagePercentage, setBaseWagePercentage] = useState<number>()
        const [hourlyRate, setHourlyRate] = useState<number>()

        useEffect(() => {
            if (isEdit) {
                setId(data.id)
                setName(data.name)
                setIsItGivenAsFixedAmount(data.isItGivenAsFixedAmount)
                setBaseWagePercentage(data.baseWagePercentage)
                setHourlyRate(data.hourlyRate)
            }
        }, [])

        const submit = async () => {
            if (!name || name == ''
                || (isItGivenAsFixedAmount ? !hourlyRate : !baseWagePercentage)
            ) {
                toast.error(t('Please fill the required fields'))
                return
            }

            const dto: any = {
                id: id,
                versionId: versionId,
                name: name,
                isItGivenAsFixedAmount: isItGivenAsFixedAmount,
                baseWagePercentage: baseWagePercentage,
                hourlyRate: hourlyRate
            }
            await store!.save(dto, versionId)
            toggleModal()
        }

        return (
            <Modal isOpen={true} toggle={toggleModal} className="modal-lg">
                <ModalHeader toggle={toggleModal}>{t('Wage Bonus')}</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-md-12">
                            <form className="needs-validation" noValidate>
                                <div className="form-row py-3">
                                    <div className="form-group w-100">
                                        <label htmlFor="name" className="">
                                            {t('Name')}
                                        </label>

                                        <input
                                            className="form-control"
                                            name="name"
                                            id="name"
                                            onChange={(event) => {
                                                setName(event.target.value)
                                            }}
                                            value={name}
                                            disabled={true}
                                        />
                                    </div>

                                    <div className="d-flex" style={{ marginBottom: '10px', marginLeft: '0px!important' }}>
                                        <div className="custom-control custom-radio px-5">
                                            <input
                                                type="radio"
                                                className="custom-control-input"
                                                id="typeRadio1"
                                                name="type"
                                                value="1"
                                                defaultChecked={isItGivenAsFixedAmount}
                                                checked={isItGivenAsFixedAmount}
                                                onChange={(value) => {
                                                    if (value.target.checked) {
                                                        setIsItGivenAsFixedAmount(true)
                                                    }
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="typeRadio1">
                                                {t('Given as a Fixed Amount')}
                                            </label>
                                        </div>
                                        <div className="custom-control custom-radio px-5">
                                            <input
                                                type="radio"
                                                className="custom-control-input"
                                                id="typeRadio0"
                                                name="type"
                                                value="0"
                                                checked={!isItGivenAsFixedAmount}
                                                onChange={(value) => {
                                                    if (value.target.checked) {
                                                        setIsItGivenAsFixedAmount(false)
                                                    }
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="typeRadio0">
                                                {t('Given as a Percentage')}
                                            </label>
                                        </div>
                                    </div>

                                    {!isItGivenAsFixedAmount && <div className="form-group w-100">
                                        <label htmlFor="baseWagePercentage" className="">
                                            {t('Base Wage Percentage')} <strong className="text-danger">*</strong>
                                        </label>

                                        <input
                                            className="form-control"
                                            name="baseWagePercentage"
                                            id="baseWagePercentage"
                                            type="number"
                                            defaultValue={baseWagePercentage}
                                            onChange={(event) => {
                                                const { value } = event.target
                                                setBaseWagePercentage(Number(value))
                                            }}
                                        />
                                    </div>}

                                    {isItGivenAsFixedAmount && <div className="form-group w-100">
                                        <label htmlFor="hourlyRate" className="">
                                            {t('Hourly Rate')} <strong className="text-danger">*</strong>
                                        </label>

                                        <input
                                            className="form-control"
                                            name="hourlyRate"
                                            id="hourlyRate"
                                            type="number"
                                            defaultValue={hourlyRate}
                                            onChange={(event) => {
                                                const { value } = event.target
                                                setHourlyRate(Number(value))
                                            }}
                                        />
                                    </div>}

                                </div>
                            </form>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className="btn btn-light mr-auto" onClick={toggleModal} icon={<i className="fas fa-times mr-2"></i>} text={t('Close')} />

                    <Button
                        className="btn btn-success float-right"
                        onClick={handleSubmit(async (data: any) => await submit())}
                        icon={<i className="far fa-save mr-2"></i>}
                        text={t('Save')}
                        showConfirm={true}
                    />
                </ModalFooter>
            </Modal>
        )
    })
)

export default View
