import React, { FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Button } from '../../../../../components'
import * as _ from 'lodash'
import Store from './store'
import { useForm } from 'react-hook-form'
import { ConventionalOfferNightHoursDto } from '../types'
import { toast } from 'react-toastify'
import moment from 'moment'
import { TimePicker } from 'antd'

interface IView {
  ConventionalOfferNightHoursStore?: Store
  data: ConventionalOfferNightHoursDto
  isEdit: boolean
  toggleModal: any
  versionId: number
}

const View: FC<IView> = inject('ConventionalOfferNightHoursStore')(
  observer((props: IView) => {
    const { ConventionalOfferNightHoursStore: store, data, isEdit, toggleModal, versionId } = props
    const { t } = useTranslation()

    const { handleSubmit } = useForm()

    const [id, setId] = useState(-1)
    const [name, setName] = useState('')
    const [startTime, setStartTime] = useState<Date>()
    const [endTime, setEndTime] = useState<Date>()

    useEffect(() => {
      if (isEdit) {
        const startTimeHourAndMinute = data.startTimeStr?.split(':')
        const endTimeHourAndMinute = data.endTimeStr?.split(':')
        setId(data.id)
        setName(data.name)
        setStartTime(moment(new Date(0, 0, 1, Number((startTimeHourAndMinute || [])[0]), Number((startTimeHourAndMinute || [])[1]), 0, 0)))
        setEndTime(moment(new Date(0, 0, 1, Number((endTimeHourAndMinute || [])[0]), Number((endTimeHourAndMinute || [])[1]), 0, 0)))
      }
    }, [])

    const submit = async () => {
      if (!name || name == '' || !startTime || !endTime) {
        toast.error(t('Please fill the required fields'))
        return
      }

      const dto: any = {
        id: id,
        versionId: versionId,
        name: name,
        startTimeStr: moment(startTime).format('HH:mm'),
        endTimeStr: moment(endTime).format('HH:mm')
      }
      await store!.save(dto, versionId)
      toggleModal()
    }

    return (
      <Modal isOpen={true} toggle={toggleModal} className="modal-lg">
        <ModalHeader toggle={toggleModal}>{t('Night Hours')}</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <form className="needs-validation" noValidate>
                <div className="form-row py-3">
                  <div className="form-group w-100">
                    <label htmlFor="name" className="">
                      {t('Name')}
                    </label>

                    <input
                      className="form-control"
                      name="name"
                      id="name"
                      onChange={(event) => {
                        setName(event.target.value)
                      }}
                      value={name}
                      disabled={true}
                    />
                  </div>

                  <div className="form-group w-100">
                    <label htmlFor="startTime" className="">
                      {t('Start Time')} <strong className="text-danger">*</strong>
                    </label>

                    <TimePicker
                        value={startTime}
                        format={'HH:mm'}
                        autoComplete="off"
                        showHour={true}
                        showMinute={true}
                        minuteStep={1}
                        onChange={(selected) => setStartTime(selected)}
                        className='form-control'
                    />
                  </div>

                  <div className="form-group w-100">
                    <label htmlFor="endTime" className="">
                      {t('End Time')} <strong className="text-danger">*</strong>
                    </label>

                    <TimePicker
                        value={endTime}
                        format={'HH:mm'}
                        autoComplete="off"
                        showHour={true}
                        showMinute={true}
                        minuteStep={1}
                        onChange={(selected) => setEndTime(selected)}
                        className='form-control'
                    />
                  </div>

                </div>
              </form>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-light mr-auto" onClick={toggleModal} icon={<i className="fas fa-times mr-2"></i>} text={t('Close')} />

          <Button
            className="btn btn-success float-right"
            onClick={handleSubmit(async (data: any) => await submit())}
            icon={<i className="far fa-save mr-2"></i>}
            text={t('Save')}
            showConfirm={true}
          />
        </ModalFooter>
      </Modal>
    )
  })
)

export default View
