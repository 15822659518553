import React, { FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Button } from '../../../../../components'
import * as _ from 'lodash'
import Store from './store'
import { useForm } from 'react-hook-form'
import { ConventionalOfferWageDto } from '../types'
import { toast } from 'react-toastify'
import { Combobox } from 'react-widgets/cjs'

interface IView {
  ConventionalOfferWageStore?: Store
  data: ConventionalOfferWageDto
  isEdit: boolean
  toggleModal: any
  versionId: number
}

const View: FC<IView> = inject('ConventionalOfferWageStore')(
  observer((props: IView) => {
    const { ConventionalOfferWageStore: store, data, isEdit, toggleModal, versionId } = props
    const { t } = useTranslation()

    const { handleSubmit } = useForm()

    const [id, setId] = useState(-1)
    const [name, setName] = useState('')
    const [recommended, setRecommended] = useState<number>()
    const [minimum, setMinimum] = useState<number>()
    const [maximum, setMaximum] = useState<number>()
    const [isAllowedToGoBelowMin, setIsAllowedToGoBelowMin] = useState(false)
    const [isAllowedToGoOverMax, setIsAllowedToGoOverMax] = useState(false)

    useEffect(() => {
      if (isEdit) {
        setId(data.id)
        setName(data.name)
        setRecommended(data.recommended)
        setMinimum(data.minimum)
        setMaximum(data.maximum)
        setIsAllowedToGoBelowMin(data.isAllowedToGoBelowMin === true)
        setIsAllowedToGoOverMax(data.isAllowedToGoOverMax === true)
      }
    }, [])

    const submit = async () => {
      if (!name || name == '' || !recommended) {
        toast.error(t('Please fill the required fields'))
        return
      }

      const dto: any = {
        id: id,
        versionId: versionId,
        name: name,
        recommended: recommended,
        minimum: minimum,
        maximum: maximum,
        isAllowedToGoBelowMin: isAllowedToGoBelowMin,
        isAllowedToGoOverMax: isAllowedToGoOverMax
      }
      await store!.save(dto, versionId)
      toggleModal()
    }

    return (
      <Modal isOpen={true} toggle={toggleModal} className="modal-lg">
        <ModalHeader toggle={toggleModal}>{t('Wage')}</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <form className="needs-validation" noValidate>
                <div className="form-row py-3">
                  <div className="form-group w-100">
                    <label htmlFor="name" className="">
                      {t('Name')}
                    </label>

                    <input
                      className="form-control"
                      name="name"
                      id="name"
                      onChange={(event) => {
                        setName(event.target.value)
                      }}
                      value={name}
                      disabled={true}
                    />
                  </div>

                  <div className="form-group w-100">
                    <label htmlFor="recommended" className="">
                      {t('Recommended')} <strong className="text-danger">*</strong>
                    </label>

                    <input
                      className="form-control"
                      name="recommended"
                      id="recommended"
                      type="number"
                      defaultValue={recommended}
                      onChange={(event) => {
                        const { value } = event.target
                        setRecommended(Number(value))
                      }}
                    />
                  </div>

                  <div className="form-group w-100">
                    <label htmlFor="minimum" className="">
                      {'Minimum'}
                    </label>

                    <input
                      className="form-control"
                      name="minimum"
                      id="minimum"
                      type="number"
                      defaultValue={minimum}
                      onChange={(event) => {
                        const { value } = event.target
                        setMinimum(Number(value))
                      }}
                    />
                  </div>

                  <div className="form-group w-100">
                    <label htmlFor="maximum" className="">
                      {t('Maximum')}
                    </label>

                    <input
                      className="form-control"
                      name="maximum"
                      id="maximum"
                      type="number"
                      defaultValue={maximum}
                      onChange={(event) => {
                        const { value } = event.target
                        setMaximum(Number(value))
                      }}
                    />
                  </div>

                  <div className="form-group w-100">
                    <label htmlFor="isAllowedToGoBelowMin" className="">
                      {t('Is Allowed To Go Below Minimum')}
                    </label>

                    <Combobox
                      id="isAllowedToGoBelowMin"
                      filter="contains"
                      data={[{ value: false, name: t('No') }, { value: true, name: t('Yes') }]}
                      value={isAllowedToGoBelowMin ? t('Yes') : t('No')}
                      textField="name"
                      onSelect={(val: any) => {
                        setIsAllowedToGoBelowMin(val.value)
                      }}
                    />
                  </div>

                  <div className="form-group w-100">
                    <label htmlFor="isAllowedToGoOverMax" className="">
                      {t('Is Allowed To Go Over Maximum')}
                    </label>

                    <Combobox
                      id="isAllowedToGoOverMax"
                      filter="contains"
                      data={[{ value: false, name: t('No') }, { value: true, name: t('Yes') }]}
                      value={isAllowedToGoOverMax ? t('Yes') : t('No')}
                      textField="name"
                      onSelect={(val: any) => {
                        setIsAllowedToGoOverMax(val.value)
                      }}
                    />
                  </div>

                </div>
              </form>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-light mr-auto" onClick={toggleModal} icon={<i className="fas fa-times mr-2"></i>} text={t('Close')} />

          <Button
            className="btn btn-success float-right"
            onClick={handleSubmit(async (data: any) => await submit())}
            icon={<i className="far fa-save mr-2"></i>}
            text={t('Save')}
            showConfirm={true}
          />
        </ModalFooter>
      </Modal>
    )
  })
)

export default View
