import React, { useState, useEffect, FC } from 'react'
import { observer, inject } from 'mobx-react'
import * as _ from 'lodash'
import ConventionalOfferWage from '../wage'
import ConventionalOfferNightHours from '../night-hours'
import ConventionalOfferAdditionalCost from '../additional-cost'
import ConventionalOfferWageBonus from '../wage-bonus'
import ConventionalOfferHalfDayHoliday from '../half-day-holiday'
import { useHistory, useParams } from 'react-router-dom'
import Store from '../store'
import { Loading } from '@/components'
import { ConventionalOfferVersionDto } from '../types'

interface IPage {
  ConventionalOfferVersionStore?: Store
  t: any
}

const Page: FC<IPage> = inject('ConventionalOfferVersionStore')(
  observer((props: IPage) => {
    const { ConventionalOfferVersionStore: store, t } = props

    const { versionId }: any = useParams()

    const history = useHistory()

    const [isLoading, setIsLoading] = useState(true)
    const [version, setVersion] = useState<ConventionalOfferVersionDto>()

    useEffect(() => {
      store!.getSingleVersion(versionId).then((res) => {
        setVersion(res)
        setIsLoading(false)
      })
    }, [])

    if (versionId == null || versionId < 1) {
      return <div>{t('Unknown Error')}</div>
    }

    if (isLoading) {
      return <Loading />
    }

    return (
      <>
        <div className="card-header">
          <div>
            {version?.name + ' - ' + t('Unit') + ': ' + version?.orgUnit?.name}
          </div>
          <div className="btn-actions-pane-right actions-icon-btn">
            <button
              className="btn btn-danger btn-shadow flex-direction float-right"
              onClick={() => history.push(`/digital-offer/conventional-offer-admin`)}
            >
              &laquo; {t('Return To Versions')}
            </button>
          </div>
        </div>

        <div className="d-flex justify-content">
          <div className="col-md-12">
            <ConventionalOfferWage versionId={versionId} disabled={!version || version.lockTime != undefined} />
          </div>
        </div>

        <div className="d-flex justify-content">
          <div className="col-md-12">
            <ConventionalOfferNightHours versionId={versionId} disabled={!version || version.lockTime != undefined} />
          </div>
        </div>

        <div className="d-flex justify-content">
          <div className="col-md-12">
            <ConventionalOfferAdditionalCost versionId={versionId} disabled={!version || version.lockTime != undefined} />
          </div>
        </div>

        <div className="d-flex justify-content">
          <div className="col-md-12">
            <ConventionalOfferWageBonus versionId={versionId} disabled={!version || version.lockTime != undefined} />
          </div>
        </div>

        <div className="d-flex justify-content">
          <div className="col-md-12">
            <ConventionalOfferHalfDayHoliday versionId={versionId} disabled={!version || version.lockTime != undefined} />
          </div>
        </div>
        
      </>
    )
  })
)

export default Page
