import React, { useState, FC, useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import * as _ from 'lodash'
import { Button, Table } from '../../../../../components'
import { useTranslation } from 'react-i18next'
import Store from './store'
import View from './view'
import { ConventionalOfferNightHoursDto } from '../types'

interface IPage {
    ConventionalOfferNightHoursStore?: Store
    versionId: number
    disabled: boolean
}

const Page: FC<IPage> = inject('ConventionalOfferNightHoursStore')(
    observer((props: IPage) => {
        const { ConventionalOfferNightHoursStore: store, versionId, disabled } = props
        const { records } = store!
        const { t } = useTranslation()
        const [selectedRow, selectRow] = useState<ConventionalOfferNightHoursDto>({} as ConventionalOfferNightHoursDto)

        const [edit, setEdit] = useState(false)

        const [showModal, setShowModal] = useState(false)

        const columns = useMemo(
            () => [
                {
                    Header: <i style={{ color: 'blue' }} className="fas fa-cog fa-2x"></i>,
                    id: 'actions',
                    Cell: ({ row }: any) => {
                        row = Object.assign({}, row.original)
                        return (
                            <>
                                {!disabled && <Button
                                    icon={<i className="fas fa-pen"></i>}
                                    tooltipText={t('Edit')}
                                    onClick={async () => {
                                        selectRow(row)
                                        setEdit(true)
                                        setShowModal(true)
                                    }}
                                    className="btn btn-link text-warning"
                                />}
                            </>
                        )
                    },
                    className: 'd-flex justify-content-center text-center border-bottom-0',
                },
                {
                    Header: t('Name'),
                    id: 'name',
                    accessor: 'name',
                    Cell: ({ row }: any) => {
                        const { name } = row.values

                        return <span dangerouslySetInnerHTML={{ __html: name }}></span>
                    },
                    className: 'd-none d-md-table-cell',
                },
                {
                    Header: t('Start Time'),
                    id: 'startTimeStr',
                    accessor: 'startTimeStr',
                    Cell: ({ row }: any) => {
                        const { startTimeStr } = row.values

                        return <span dangerouslySetInnerHTML={{ __html: startTimeStr }}></span>
                    },
                    className: 'd-none d-md-table-cell',
                },
                {
                    Header: t('End Time'),
                    id: 'endTimeStr',
                    accessor: 'endTimeStr',
                    Cell: ({ row }: any) => {
                        const { endTimeStr } = row.values

                        return <span dangerouslySetInnerHTML={{ __html: endTimeStr }}></span>
                    },
                    className: 'd-none d-md-table-cell',
                }
            ],
            [t]
        )

        return (
            <>
                <div className="card-body">
                    <div className="card-header">
                        <div className="row">{t('Night Hours')}</div>
                    </div>

                    <br></br>

                    <Table
                        smallSize={true}
                        columns={columns}
                        totalCount={records.totalCount}
                        data={records.items}
                        fetchData={(x) => store?.getRecords(x, versionId)}
                        isLoading={!records.isLoaded}
                        showHeaderSearchInput={false}
                    />
                </div>

                {showModal && <View data={selectedRow!} isEdit={edit} toggleModal={() => setShowModal(!showModal)} versionId={versionId} />}
            </>
        )
    })
)

export default Page
